.section__padding {
    padding: 4rem 6rem;
  }
  
  .flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app__bg {
    background: url('./assets2/bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
  }
  
  .app__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    min-height: 100vh;
  }
  
  .app__wrapper_info {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .app__wrapper_img {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    margin-left: 2rem;
  }
  
  .app__wrapper_img-reverse {
    justify-content: flex-start;
    margin-right: 2rem;
  }
  
  .app__wrapper_img img {
    width: 80%;
  }

  .app__wrapper_imgo {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    margin-left: 2rem;
  }
  
  .app__wrapper_img-reverseo {
    justify-content: flex-start;
    margin-right: 2rem;
  }
  
  .app__wrapper_imgo img {
    width: 80%;
  }

  .app__wrapper_pro_img {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 2rem;
  }
  
  .app__wrapper_pro_img-reverse {
    justify-content: flex-start;
    margin-right: 2rem;
  }
  
  .app__wrapper_pro_img img {
    width: 80%;
    display: block;
    object-fit: cover;
    height: auto;
  }
  
  .custom__button {
      padding: .7rem 1.5rem;
      margin: 1rem 0;
      background-color: var(--color-gold);
      border: 1px solid var(--color-gold);
      color: var(--color-black);

      font-size: 1rem;
      display: flex;
      border-radius: 4px;
    
      font-family: var(--font-alt);
      font-weight: 600;
      /*letter-spacing: 0.04em;*/
      cursor: pointer;
  }

  .custom__button:hover {
    transform: scale(1.1);
    transition-duration: 0.2s;
    
  }

  
  .p__cormorant {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 29.9px;
    font-size: 23px;
  }
  
  .p__opensans {
    font-family: var(--font-alt);
    color: var(--color-white);
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
  }
  
  .headtext__cormorant {
    font-family: var(--font-base);
    color: var(--color-gold);
    font-size: 64px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
  }
  
  .pencil__img {
    width: 45px;
  }
  
  @media screen and (min-width: 2000px) {
    .custom__button,
    .p__cormorant {
      font-size: 37px;
      line-height: 67px;
    }
  
    .p__opensans {
      font-size: 30px;
      line-height: 50px;
    }
  
    .headtext__cormorant {
      font-size: 150px;
      line-height: 210px;
    }
  
    .pencil__img {
      width: 80px;
    }
  }
  
  @media screen and (max-width: 1150px) {
    .app__wrapper {
      flex-direction: column;
    }
  
    .app__wrapper_img {
      margin: 5rem 0 0 0;
    }

    .app__wrapper_imgo {
      margin: 5rem 0 0 0;
    }

  
    .app__wrapper_img-reverse {
      margin: 0 0 5rem 0;
    }

    .app__wrapper_img-reverseo {
      margin: 0 0 5rem 0;
    }
  
    .app__wrapper_img img {
      width: 100%;
    }

    .app__wrapper_imgo img {
      width: 100%;
      margin-right: 30px;
    }
  }
  
  @media screen and (max-width: 850px) {
    .section__padding {
      padding: 4rem;
    }
  }
  
  @media screen and (max-width: 650px) {
    .section__padding {
      padding: 4rem 2rem;
    }
    
    .p__cormorant {
      font-size: 21px;
    }
  
    .p__opensans {
      font-size: 14px;
    }

    .app__bg {
      background: url('./assets2/bg.png');
      background-position: center;
      background-size: cover;
      background-repeat: repeat;
      background-attachment: fixed;
    }
  }
  
  @media screen and (max-width: 450px) {
    .p__opensans {
      font-size: 12px;
    }
  
    .p__cormorant {
      font-size: 19px;
    }
  
    .headtext__cormorant {
      font-size: 45px;
      line-height: 70px;
    }

    .app__video {
      width: 100%;
      height: 100%;
  }

  .app__bg {
    background: url('./assets2/bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
  }

  .app__wrapper1 {
    position: relative;
    right: 90px;
  }

  }