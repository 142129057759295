*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: #fff;
    padding: 0px;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    display: inline-block;
}

.grid-wrapper .Imago{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.grid-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 7px 0 rgba(0, 0, 0, 0.19);

}

.grid-wrapper img:hover{
width: 100%;
    height: 100%;
    -webkit-transform:scale(1.02); /* Safari and Chrome */
    -moz-transform:scale(1.02); /* Firefox */
    -ms-transform:scale(1.02); /* IE 9 */
    -o-transform:scale(1.02); /* Opera */
     transform:scale(1.02);
     transition-duration: 0.2s;
    }

.grid-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-auto-rows: 450px;
    grid-auto-flow: dense;
}

.grid-wrapper .wide {
    grid-column: span 2;
}

.grid-wrapper .tall {
    grid-row: span 2;
}

.grid-wrapper .big {
    grid-column: span 2;
    grid-row: span 2;
}

.Imago .popup-imag {
    position: fixed;
    top: 0; left: 0;
    background: rgba(0,0,0,.9);
    height: 100%;
    width: 100%;
    z-index: 100;
    display: none;
}

.Imago .popup-imag span{
    position: absolute;
    top: 0; right: 10px;
    font-size: 60px;
    font-weight: bolder;
    color: #fff;
    cursor: pointer;
    z-index: 100;

}

.Imago .popup-imag img{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid #fff;
    border-radius: 5px;
    width: 750px;
    object-fit: cover;

}

@media screen and (min-width: 1150px){
    .grid-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;

}}

@media screen and (max-width: 630px) {
    .grid-wrapper {
        display: flex!important;
        flex-direction: column;
    }}

@media screen and (max-width: 738px) {
        .grid-wrapper {
            display: flex!important;
            flex-direction: column;
        }
}

/*------Modal image css----*/

.popuplightboxes{
    z-index: 20;
    position: fixed;
}

.popuplightbox{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0,.5);
    color: white;
    align-items: center;
    justify-content: center;
    transform: scale(0, 0);
    transition: transform ease-in-out 250ms;
}

.popuplightbox:target{
    transform: scale(1, 1);
}

.popupcontent{
    width: 75%;
    background: black;
    padding: 1em;
    position: relative;
}

.close{
    position: absolute;
    width: 2em;
    height: 2em;
    top:-1em;
    background: var(--color-reddo);
    right: -1em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close::after{
    content: 'x';
    color: white;
    font-weight: 700 ;
    
}
.popuptitle{
    color: #fbfbfb;
}

.popupbody{
    color: #fbfbfb;
}

@media screen and (min-width: 978px){
.popupcontent{
    width: 37%;
    position: relative;
    top: 60px;
}
}