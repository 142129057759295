* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  section {
    display: flex;
    place-items: center;
    flex-direction: column;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .column {
    width: 100%;
    padding: 0 1em 1em 1em;
    text-align: center;
  }
  .card {
    width: 100%;
    height: 100%;
    padding: 2em 1.5em;
    background: linear-gradient(#f3eabf 50%, var(--color-gold) 50%);
    background-size: 100% 200%;
    background-position: 0 2.5%;
    border-radius: 55px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
  }

  h3 {
    font-family: var(--font-alt);
    font-size: 20px;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
  }
  p {
    font-family: var(--font-alt);
    color: #575a7b;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
  }
  .icon-wrapper {
    background-color: var(--color-gold);
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #f3eabf;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
  }

i{
    top: 4px;
    position: relative;
  }


  .card:hover {
    background-position: 0 100%;
  }
  .card:hover .icon-wrapper {
    background-color: #ffffff;
    color: var(--color-gold);
  }
  .card:hover h3 {
    color: #fbfbfb;
  }
  .card:hover p {
    color: #fbfbfb;
  }
  @media screen and (min-width: 768px) {
    section {
      padding: 0 2em;
    }
    .column {
      flex: 0 50%;
      max-width: 50%;
    }
  }
  @media screen and (min-width: 992px) {
    section {
      padding: 1em 3em;
    }
    .column {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }