@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');


:root {
  --primary-color: #0fB8e0;
  --primary-dark: #0d2154;
  --font-base: 'Cormorant Upright', serif;
  --font-alt: 'Open Sans', sans-serif;
  
  --color-gold: #dbc36a;
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FBFBFB;
  --color-reddo: #ad2831;
}
span {
  color: var(--color-gold);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #FBFBFB;
}

/*p {
  font-size: 1.2rem;
  line-height: 1.4;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 3rem;
}*/

.container {
  max-width: 1240px;
  margin: auto;
  height: 100%;
  position: relative;
}

button {
  font-family: 'Roboto', sans-serif;
  padding: .7rem 1.5rem;
  margin: 1rem 0;
  background-color: var(--color-reddo);
  border: 1px solid var(--color-reddo);
  color: #f8f8f8;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  border-radius: 4px;
}

button:hover {
  transform: scale(1.1);
  transition-duration: 0.2s;
}

@media screen and (max-width: 940px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }
}
