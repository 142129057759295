.app__Anto-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 3rem;
}
  
  .app__wrapper1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  flex-direction: row-reverse;
    min-height: 100vh;
  }

.app__Anto-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.app__Anto-content_quote img {
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
}

.app__Anto-sign {
    width: 100%;
    margin-top: 3rem;
    
}

.app__Anto-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-gold);
}

.app__Anto-sign img {
    width: 250px;
    margin-top: 3rem;
}

.app__wrapper_imgo {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
  }


@media screen and (min-width: 2000px) {
    .app__Anto-sign img {
        width: 370px;
    }
}

@media screen and (max-width: 450px) {
    .app__Anto-sign img {
        width: 80%;
    }
    .app__wrapper1 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
        left: 0;
      }
}

@media screen and (max-width: 1150px) {
    .app__Anto-sign img {
        width: 80%;
    }
    .app__wrapper1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        min-height: 100vh;
      }
}