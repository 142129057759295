.app__header {
    /*background-color: var(--color-gold);*/
    background: rgb(107,114,84);
background: linear-gradient(53deg, rgba(107,114,84,1) 0%, rgba(220,202,135,1) 40%, rgba(255,255,230,1) 100%);
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-black);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;
}

.app__header-h2 {
    font-family: var(--font-base);
    color: var(--color-black);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 40px;
}

.app__header-img img {
    width: 80%;
}

.app__wrapper-head_img {
    width: 100%;
    position  :relative;
    /*animation :up_down 4s ease infinite;*/
    animation-name: floating;
    animation-duration: 4.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    ;
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 60px); }
    100%   { transform: translate(0, -0px); }    
}

.line {
    width: 112px;
    height: 47px;
    border-bottom: 1px solid black;
    position: absolute;
    }

/*@keyframes up_down{
    0%{
        top :10px;
    }
    50%{
        top :50px;
    }
    100%{
        top :10px;
    }
}*/

/*@-webkit-keyframes up_down{
    0%{
        top :10px;
    }
    50%{
        top :24px;
    }
    100%{
        top :10px;
    }
}*/




@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }

    .app__header-h2 {
        font-family: var(--font-base);
        color: var(--color-black);
        font-weight: 700;
        letter-spacing: 0.04em;
        text-transform: capitalize;
        line-height: 70px;
        font-size: 40px;
    }
}